/*! {!project_name!} v0.1.0 */
/**
 * JS for: {!project_name!} v0.1.0
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 */
const backstretch = require('jquery-backstretch');
const lightcase = require('lightcase');


/**
 * Toggle display result message
 */
function fnc_result_message_display() 
{
	var obj_auto_hide;
	if ($('#result_message').length > 0 && $('#result_message').html() != '') {
		$('#result_message').click(function(event) {
			$('#result_message').slideUp('fast');
			//$('#result_message').fadeOut('fast');
			clearTimeout(obj_auto_hide);
		});
		if ($('#result_message').is(':visible')) {
			$('#result_message').fadeTo('fast', 0.5).fadeTo('slow', 1.0);
		} else {
			$('#result_message').slideDown('slow');
			//$('#result_message').fadeIn('slow');
			obj_auto_hide = setTimeout(function() {
				$('#result_message').slideUp('slow');
			}, 6000);
		}
	}
}


function fnc_menu_toggle()
{
	var str_toggle_class = 'collapsed';
	if ( $( window ).width() < 800 ) {
		str_toggle_class = 'expanded';
	}	
	$( 'body' ).toggleClass( str_toggle_class );
}
function fnc_header_minify()
{
	$( 'body' ).toggleClass( 'minified' );
}

let current_section = 'home';
function slide_navigation(section)
{
  console.log(section);
  if ('home' != current_section){
    
    $('#' + current_section).animate({marginLeft: '-50%'}, 1000, function()
    {
        $('#' + secction).animate({marginLeft: '0'}, 500);
    });
  }
}


/**
 * jQuery init
 * Initialize all jquery/js functions when DOM ready
 */

let main_images = [
	'img/home/home1.jpg',
  'img/home/home2.jpg',
  'img/home/home3.jpg',
  'img/home/home4.jpg'
];
/*
let main_images_data = [
	['Estadio', 'Diablos rojos de México.', 'down'],
	['Línea 3', 'Tren ligero Guadalajara, Jalisco.', 'up']
];
*/

$(document).ready(function()
{
	/* The slider */
	$.backstretch(
    main_images,
    {
		duration: 12000, 
		fade: 500
	});
  /*
	$(window).on('backstretch.after', function (e, instance, index)
	{
		if ($('.image-caption').length){
  		$('.image-caption .heading').html(main_images_data[index][0]);
  		$('.image-caption .sub-heading').html(main_images_data[index][1]);
  		$('.image-caption').removeClass('up down').addClass(main_images_data[index][2]);
		}
	});
  */

  // The slide navigation
  /*
  $('nav.main a').on('click', function(e)
  {
    let section = $(this).attr('href');
    slide_navigation(section);
    e.preventDefault();
  });
  */

	//fnc_menu_toggle();
	/* The result message */
	fnc_result_message_display();
	/* The form validation */
	$('form').submit(function(event) {
		return fnc_validate_form($(this).attr('id'));
		event.preventDefault();
		event.stopPropagation();
	});


	/* The menu collapse */
	$('#link_menu_toggle').click(function()
	{
		fnc_menu_toggle();
		event.preventDefault();
		event.stopPropagation();
	});


	/* The compact header scroll effect */
	var int_scroll_position = 0;
	$( window ).scroll( function() {
		int_scroll_position = Math.round($(window).scrollTop());
		if ( int_scroll_position > 70 && $( window ).width() < 800 ) {
			$( 'body' ).addClass( 'minified' );
		} else {
			$( 'body' ).removeClass( 'minified' );
		}
	});


	/* The lightbox stuff */
	$( 'a[data-rel^=lightcase_project]' ).lightcase({
		transition: 'elastic',
		showTitle: false
	});
	$( 'a[data-rel^=lightcase_gallery]' ).lightcase({
		transition: 'elastic',
		showTitle: true
	});


	/* The project filter */
	$('#year_selector').change(function()
	{
		var int_year = $( this ).val();
		var str_item_years;
		var int_show;

		$( '.item' ).each( function( index, object ) {
			str_item_years = String( $( this ).data( 'project_date' ) );
			int_show = str_item_years.indexOf( '' + int_year + '' );
			if ( int_year == '' || int_show >= 0 ) {
				$( this ).fadeIn( 'slow' );
			} else {
				$( this ).fadeOut( 'fast' );
			}
		});
		window.location.hash = int_year; //* Add hash
	});


});